// btn
.btn {
	display: inline-flex;
	align-items: center;
	border-radius: 2px;
	box-shadow: $shadow;
	color: $orange-light;
	font-family: $font-family-secondary;

	@include media-breakpoint-up(xl) {
		padding: 12px 24px;
	}


	&:not(.btn-link) {
		&::after {
			content: '';
			width: 27px;
			height: 18px;
			margin-left: 15px;
			background-image: url('/images/btn-arrow.svg');
			transition: 0.5s;
		}

		&:hover {
			&::after {
				transform: translateX(5px);
			}
		}
	}

	// btn-primary
	&.btn-primary {

		&:hover {
			background-color: lighten($primary, 15%);
			border-color: lighten($primary, 15%);
			color: $orange-light;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		color: $primary;

		&:hover {
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {

		&:hover {
			background-color: darker($secondary, 15%);
			border-color: darker($secondary, 15%);
			color: $orange-light;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		color: $secondary;

		&:hover {
			color: $white;
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	margin-bottom: 0 !important;
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;
	color: $white;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		color: $green;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
