// menu-overlay
.menu-overlay {
	.container-one-column {
		.container-holder {
			height: 100%;
			overflow: hidden;
			overflow-y: auto;
		}
	}

	// menu-overlay-top
	.menu-overlay-top {
		padding: 15px;
	}

	// menu-overlay-bottom
	.menu-overlay-bottom {
		position: relative;
		margin-top: 70px;
		padding: 15px;
		background-color: $green-light;

		&::before {
			content: '';
			z-index: 100;
			position: absolute;
			left: -15px;
			bottom: 100%;
			width: calc(100% + 15px);
			height: 72px;
			background: url('/images/bush-green-light-up.svg') top left repeat-x;
		}

		&::after {
			content: '';
			z-index: 100;
			position: absolute;
			top: -150px;
			right: 15px;
			width: 113px;
			height: 153px;
			background: url('/images/dear.svg');
			background-size: cover;

			@include media-breakpoint-up(xl) {
				top: -229px;
				width: 169px;
				height: 229px;
			}
		}

		// global-icons
		div.global-icons {
			ul {
				justify-content: center;

				li {
					margin: 2.5px;

					a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 50px;
						height: 50px;
						border-radius: 2px;
						background: $green-lighter;
						color: $green;
						font-size: 20px;
						text-align: center;
						text-decoration: none;
						transition: 0.5s;

						.list-item-title {
							display: none;
						}

						&:hover {
							background: $green;
							color: $white;
						}
					}
				}
			}
		}

		// global-address
		div.global-address {
			margin-top: 15px;

			ul {
				justify-content: center;

				li {
					color: $green;
					font-size: 13px;
				}
			}
		}
	}

	// menu
	.menu {
		display: flex;
		align-items: flex-start;

		@include media-breakpoint-down(lg) {
			.navbar-nav {
				width: 100%;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $green;
						font-size: 16px;
						font-family: $font-family-secondary;

						&:hover {
							color: $orange-dark;
						}
					}

					&.active {
						>.nav-link {
							color: $orange-dark;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								padding: 0.35rem 0.5rem;
								color: $black;

								&:hover {
									color: $orange-dark;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $orange-dark;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			padding-top: 30px;
			justify-content: center;

			.navbar-nav {
				flex-wrap: wrap;
				flex-direction: row;

				>.nav-item {
					margin-bottom: 30px;
					padding-right: 30px;
					font-size: 16px;
					font-family: $font-family-secondary;

					>.nav-link {
						padding: 0.25rem 0.5rem;
						color: $green;

						&.dropdown-toggle {
							pointer-events: none;
						}

						&::after {
							display: none;
						}

						&:hover {
							color: $orange-dark;
						}
					}

					&.active {
						>.nav-link {
							color: $orange-dark;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						display: block;
						margin: 0;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;
							font-family: $font-family-primary;

							.nav-link {
								padding: 0.35rem 0.5rem;
								color: $black;

								&:hover {
									color: $orange-dark;
								}
							}

							&.active {
								.nav-link {
									color: $orange-dark;
								}
							}
						}
					}
				}
			}
		}
	}
}
