// mini-sab
.mini-sab {
	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: absolute;
			left: auto;
			right: auto;
			bottom: 45px;
		}
	}
}

&.home {
	.mini-sab {
		.container {
			.container-holder {
				@include media-breakpoint-up(xl) {
					bottom: 75px;
				}
			}
		}
	}
}
