// footer
.footer {
	position: relative;

	&::before {
		content: '';
		z-index: 100;
		position: absolute;
		top: -150px;
		right: 15px;
		width: 113px;
		height: 153px;
		background: url('/images/dear.svg');
		background-size: cover;

		@include media-breakpoint-only(lg) {
			top: -220px;
			width: 169px;
			height: 229px;
		}

		@include media-breakpoint-up(xl) {
			top: -290px;
			width: 225px;
			height: 306px;
		}
	}

	// footer-outro
	.footer-outro {
		position: relative;
		padding: 45px 0 30px 0;
		background-color: $green-light;

		&::before {
			content: '';
			z-index: 100;
			position: absolute;
			bottom: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-light-up.svg') top left repeat-x;
		}

		.container-two-columns {
			.column {
				&.one {
					@include media-breakpoint-up(xl) {
						@include make-col(7);
					}
				}

				&.two {
					@include media-breakpoint-up(xl) {
						@include make-col(5);
					}

					flex-flow: row wrap;
				}
			}
		}

		// footer-text
		.footer-text {
			.btn {
				margin-right: 5px;

				&::after {
					display: none;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			ul {
				margin-left: -5px;
				justify-content: center;

				li {
					margin-left: 5px;

					a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 38px;
						height: 38px;
						border-radius: 2px;
						background: $green-lighter;
						color: $green;
						font-size: 24px;
						text-align: center;
						text-decoration: none;
						transition: 0.5s;

						@include media-breakpoint-up(xl) {
							width: 50px;
							height: 50px;
						}

						.list-item-title {
							display: none;
						}

						&:hover {
							background: $green;
							color: $white;
						}
					}
				}
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		padding-top: calc(40px + 6vh);
		padding-bottom: 6vh;

		a {
			text-decoration: none;
		}

		.container-three-columns {
			.column {
				&.one {
					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}

				&.two {
					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}

				&.three {
					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					flex-flow: row wrap;
					padding-top: 30px;

					// footer-text
					.footer-text {
						flex: 1;
						min-width: 160px;

						h6 {
							margin-bottom: 10px;
						}

						ul {
							li {
								padding: 4px 0;
							}
						}
					}
				}
			}
		}

		// footer-logo
		.footer-logo {
			margin: -40px 0 15px 0;
		}

		// footer-text
		.footer-text {
			ul {
				padding-left: 0;
				list-style: none;
			}
		}

		// footer-text-address
		.footer-text-address {
			ul {
				li {
					padding: 3px 0;

					a {
						display: flex;
						align-items: center;
						text-decoration: none;
						color: $orange-dark;
						font-weight: 700;

						&::before {
							margin-right: 10px;
							font-size: 16px;
							font-weight: 900;
							font-family: $font-awesome;
						}

						&[href^='tel:'] {
							&::before {
								content: '\f095';
							}
						}

						&[href^='mailto:'] {
							&::before {
								content: '\f0e0';
							}
						}

						&:hover {
							color: darken($orange-dark, 50%);
						}
					}
				}
			}
		}
	}

	// footer-partners
	.footer-partners {
		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 20px 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			li {
				font-size: 13px;
				font-weight: 300;

				@include media-breakpoint-up(md) {
					margin: 0 15px;
				}

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

&:not(.home) {
	.footer {
		margin-top: calc(101px + 6vh);
	}
}
