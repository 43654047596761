// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.35s;

	// header-notification
	.header-notification {
		padding: 1px 0;
		background-color: $orange-dark;
		box-shadow: $shadow;
		color: $white;
		text-align: center;

		.container,
		.container-fluid {
			.container-holder {
				>.column {
					align-items: center;
				}
			}
		}

		// global-notification
		div.global-notification {
			ul {
				li {
					@include media-breakpoint-down(xs) {
						font-size: 12px;
					}

					.list-item-title {
						display: none;
					}

					.list-item-suffix {
						p {
							margin-bottom: 0;

							a {
								color: $white;
								text-decoration: underline;

								&:hover {
									color: $black;
								}
							}

							img {
								margin-left: 15px;

								@include media-breakpoint-down(xs) {
									max-width: 125px;
								}
							}
						}
					}


				}
			}
		}
	}

	// header-main
	.header-main {
		margin-top: 30px;

		.container,
		.container-fluid {
			.container-holder {
				>.column {
					align-items: center;

					@include media-breakpoint-down(lg) {
						flex-flow: row wrap;
					}

					@extend .navbar-expand-xl;
				}
			}
		}

		// menu-toggle
		.menu-toggle {
			z-index: 1;

			.navbar-toggler {
				display: flex !important;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				padding: 0;
				outline: none;
				border: none;
				border-radius: 0;
				background: $orange;
				color: $orange-light;
				font-size: 24px;
				text-align: center;
				transition: 0.5s;
				box-shadow: 0 0 8px rgba($black, 0.25);

				@include media-breakpoint-only(lg) {
					width: 70px;
					height: 70px;
					font-size: 32px;
				}

				@include media-breakpoint-up(xl) {
					width: 90px;
					height: 90px;
					font-size: 40px;
				}

				i {
					transition: transform 0.5s;
				}

				&[aria-expanded="true"] {
					i {
						transform: rotate(180deg);

						&::before {
							content: "\f00d";
						}
					}
				}

				&:hover {
					background: $green;
				}
			}
		}

		// logo
		.logo {
			width: 231px;
			margin-left: -15px;
			transition: 0.5s;

			@include media-breakpoint-only(lg) {
				width: 300px;
				margin-left: -15px;
			}

			@include media-breakpoint-up(xl) {
				width: 437px;
				margin-left: -30px;
			}

			a {
				display: block;

				img {
					@extend .w-100;
				}
			}
		}

		// book-button
		div.book-button {
			margin-left: auto;

			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				background: $green;
				font-size: 8px;
				text-align: center;
				font-family: $font-family-secondary;
				box-shadow: 0 0 8px rgba($black, 0.25);
				transition: 0.5s;
				overflow: hidden;

				@include media-breakpoint-only(lg) {
					width: 70px;
					height: 70px;
					font-size: 11px;
				}

				@include media-breakpoint-up(xl) {
					width: 90px;
					height: 90px;
					font-size: 14px;
				}

				color: $orange-light;
				text-decoration: none;

				.list-item-title {
					order: -1;
				}

				i {
					color: $white;
					font-size: 24px;

					@include media-breakpoint-only(lg) {
						font-size: 32px;
					}

					@include media-breakpoint-up(xl) {
						font-size: 40px;
					}
				}

				&:hover {
					background: $orange;
				}
			}
		}
	}

	&.sticky {
		.logo {
			@include media-breakpoint-up(xl) {
				width: 300px;
				margin-left: -15px;
			}
		}
	}
}

&.navbar-collapse-active {
	.header {
		background-color: $green-lighter;
		box-shadow: $shadow;
	}
}
