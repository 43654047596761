& {
	font-size: 15px;
}

// main
&:not(.accommodation-book) {
	.main {
		position: relative;

		&::before {
			content: '';
			z-index: 100;
			position: absolute;
			top: -90px;
			right: 0;
			width: 65px;
			height: 126px;
			background: url('/images/dog.svg');
			background-size: cover;

			@include media-breakpoint-only(lg) {
				top: -120px;
				width: 97px;
				height: 190px;
			}

			@include media-breakpoint-up(xl) {
				top: -180px;
				width: 129px;
				height: 253px;
			}
		}
	}
}

// container
.container {
	@media (min-width: 1280px) {
		max-width: 1230px;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// headings
h5 {
	margin-bottom: 1.5rem;
	color: $orange-dark;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;

	.card-body {
		margin-top: 1rem;
		padding: 0;

		.card-caption {

			.card-title,
			.card-subtitle {
				color: $green;
			}
		}
	}
}
