// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		position: relative;
		padding: 6vh 0;
		background-image: url('/images/bg-pattern.svg');
	}

	&.bg-light {
		&::before {
			content: '';
			z-index: 90;
			position: absolute;
			bottom: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-lighter-up.svg') top left repeat-x;
		}

		&::after {
			content: '';
			z-index: 90;
			position: absolute;
			top: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-lighter-down.svg') bottom left repeat-x;
		}
	}

	&.bg-dark {
		&::before {
			content: '';
			z-index: 90;
			position: absolute;
			bottom: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-up.svg') top left repeat-x;
		}

		&::after {
			content: '';
			z-index: 90;
			position: absolute;
			top: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-down.svg') bottom left repeat-x;
		}
	}
}

// lead-section
// =========================================================================
&:not(.accommodation-detail) {
	.lead-section {
		.container-one-column {
			.container-holder {
				text-align: center;
			}
		}
	}
}

.lead-section {
	position: relative;
	margin-bottom: calc(6vh + 70px) !important;
	padding: 6vh 0;
	background: url('/images/bg-pattern.svg') $brown;

	&::before {
		content: '';
		z-index: 90;
		position: absolute;
		bottom: 100%;
		width: 100%;
		height: 72px;
		background: url('/images/bush-brown-up.svg') top left repeat-x;
	}

	&::after {
		content: '';
		z-index: 90;
		position: absolute;
		top: 100%;
		width: 100%;
		height: 72px;
		background: url('/images/bush-brown-down.svg') bottom left repeat-x
	}

	h3 {
		color: $white;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;

		.actions {
			.actions-item {
				a {
					color: $white;

					&:hover {
						color: $green;
					}
				}
			}
		}
	}

	.info {

		// subtitle
		.subtitle {
			h5 {
				margin-bottom: 0.5rem;
				color: $white;
			}
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}

	// collection
	.collection {
		margin: 30px 0 6vh 0;

		.card {
			border: none;
			border-radius: 0;

			&::before {
				content: "";
				z-index: 1;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);
			}

			.card-img-overlay {
				z-index: 2;
				display: flex;
				flex-direction: column;
				padding: 20px 40px 20px 20px;
				background: transparent;
				border-radius: 0;
				transition: 0.5s;

				@include media-breakpoint-up(xl) {
					padding: 30px 50px 30px 30px;
				}

				.card-caption {
					display: flex;
					flex-direction: column;
					flex: 0;
					margin-top: auto;
					text-align: left;
					text-shadow: none;

					.card-title-link {
						margin-bottom: 0;
					}

					.card-title {
						position: relative;
						order: 4;
						margin-bottom: 0;

						&::after {
							content: '';
							position: absolute;
							right: -10px;
							width: 39px;
							height: 25px;
							background-image: url('/images/arrow.svg');

							@include media-breakpoint-up(xl) {
								right: -20px;
							}
						}

						&:hover {
							color: $white;
						}
					}

					.card-subtitle {
						font-size: 18px;
					}

					.card-subtitle,
					.card-description {
						height: 0;
						margin: 0;
						text-shadow: none;
						overflow: hidden;
						transition: 0.5s;
					}
				}

				.card-buttons {
					display: none;
				}
			}

			&:hover {
				.card-img-overlay {
					background: rgba($black, 0.5);

					.card-caption {

						.card-subtitle,
						.card-description {
							height: auto;
						}

						.card-description {
							margin-bottom: 1rem;
						}
					}
				}
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	&.bg-green-lighter {
		position: relative;
		margin: 0;
		padding: 6vh 0;
		background: url('/images/bg-pattern.svg') $green-lighter;

		&::before {
			content: '';
			z-index: 90;
			position: absolute;
			bottom: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-lighter-up.svg') top left repeat-x;
		}

		&::after {
			content: '';
			z-index: 90;
			position: absolute;
			top: 100%;
			width: 100%;
			height: 72px;
			background: url('/images/bush-green-lighter-down.svg') bottom left repeat-x;
		}
	}
}

&.accommodation-detail {
	.content-section {
		margin-top: 6vh;
	}
}

// highlight-section
// =========================================================================
.highlight-section {
	position: relative;
	margin-top: 6vh;
	padding-bottom: 6vh;

	&::before {
		content: '';
		z-index: 100;
		position: absolute;
		top: -88px;
		left: 0;
		width: 92px;
		height: 88px;
		background: url('/images/pig.svg');
		background-size: cover;

		@include media-breakpoint-only(lg) {
			top: -132px;
			width: 137px;
			height: 132px;
		}

		@include media-breakpoint-up(xl) {
			top: -176px;
			width: 183px;
			height: 176px;
		}
	}

	&::after {
		content: '';
		z-index: 90;
		position: absolute;
		top: 100%;
		width: 100%;
		height: 72px;
		background: url('/images/bush-white-down.svg') bottom left repeat-x;
	}

	.resource-image {
		z-index: 110;

		@include media-breakpoint-up(lg) {
			margin-top: calc(-6vh - 100px);
		}

		.image-holder {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(0deg, rgba($black, 0.5) 0%, rgba($black, 0) 100%);

			}

			.image-holder-caption {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 20px 30px;

				.image-holder-caption-title {
					color: $white;
				}
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	overflow: hidden;

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 330px;
		max-height: 760px;

		.item {
			position: relative;
			height: 75vh !important; // overide
			min-height: 330px;
			max-height: 760px;
			padding: unset; // overide

			.owl-container {
				z-index: 90;
				position: relative;

				.owl-caption {
					display: flex;
					flex-direction: column;

					.owl-title {
						@include font-size($h1-font-size);
					}

					.owl-subtitle {
						order: -1;
						color: $orange-light;
					}

					.owl-btn {
						margin-top: 1rem;
					}
				}
			}
		}

		// owl-nav
		.owl-nav {
			display: none;
		}

		// owl-dots
		.owl-dots {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);

	.gallery-link {
		.gallery-label {
			top: 15px;
			padding: 5px 15px;
			border-radius: 0 2px 2px 0;
			background-color: $primary;
			color: $white !important;
		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
